
.__cursor_tracker_circle{
    position: fixed !important;
    width:30px;
    height:30px;
    border-radius: 15px;
    background-color: rgb(229, 255, 0);
    z-index: 2147483647 !important;
    transform: translate(-50%, -50%);
    opacity: 0;
    pointer-events: none;
    /* transform: translateY(-50%); */
}

.__cursor_tracker_circle_shown{
    opacity: 0.8;
    /* opacity: radial-gradient(circle, 1 0.7 0.3); */
    /* transition: opacity 0s linear 0.2s; */
    animation: blink 0.3s ease-in 0s;

    /* transition: opacity 0.2s linear 0s */
    /* -webkit-animation: tap 1s linear 0s forwards; */
    /* animation: tap 1s linear 0s forwards; */
}

@keyframes blink {
    0% {
        /* opacity:1; */
      box-shadow: 0 0 0 0 rgba(229, 255, 0, 0.8);
    }
    90% {
        /* opacity:0.8; */
      box-shadow: 0 0 0 16px rgba(229, 255, 0, 0.4);
    }
    100% {
        /* opacity:0.8; */
      box-shadow: 0 0 0 16px rgba(229, 255, 0, 0.3);
    }
  }

@-webkit-keyframes tap {
    0%{
        opacity:1;
        transform: translate(-50%, -50%) scale(0.0);
    }
    100%{
        opacity:0.8;
        transform: translate(-50%, -50%) scale(1.0);
    }
}
@keyframes tap {
    0%{
        opacity:1;
        transform: translate(-50%, -50%) scale(0.0);
    }
    100%{
        opacity:0.8;
        transform: translate(-50%, -50%) scale(1.0);
    }
}

  