
textarea#MessageTextInputArea{
    resize: none;
    /* border: 1px; */
    padding-left:2px;
    padding-right:2px;
    padding-top:4px;
    padding-bottom:4px;
    border: 1px solid #ddd;
    /* padding: .5em 1em; */
    font-size: 18px;
    line-height: 1.1em;
    transition: border-color ease .2s;
    margin-left: 5px;
    margin-right:5px;
    margin-top:5px;
    margin-bottom:5px;
}
textarea#MessageTextInputArea:focus{
    border-color: #369;
    border-color: #5f98e1;
  }

.message-thread-list-item > .list-item__center{
    padding:0px 6px 0px 0px !important;
    align-items: baseline !important;
    display:flex;
    flex-direction: column;
}