.smooth-scroll{
    overflow-y:scroll;
    overflow-x:hidden;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
}

.major-splitter {
    line-height: 1em;
    margin-top: 2em;
	position: relative;
	outline: 0;
	border: 0;
	color: black;
	text-align: center;
	height: 1.5em;
	opacity: .5;
}
.major-splitter:before {
	content: '';
	background: -webkit-linear-gradient(left,  #8180787c, #818078,  #818078,#818078, #818078,  #818078,#818078,  #8180787c);
	background: linear-gradient(to right, #8180787c, #818078,  #818078,#818078, #818078,  #818078,#818078,  #8180787c);
	position: absolute;
	left: 0;
	top: 35%;
	width: 100%;
	height: 4px;
}
.major-splitter:after {
    content:'以下、回答内訳';
	position: relative;
	display: inline-block;
	color: black;
	padding: 0 .5em;
	line-height: 1.5em;
	background-color: #efeff4;
}

.content-editable {
    -webkit-user-select: text;
    user-select: text;
    word-break: break-all;
}

#MessageTextInputArea::placeholder{
    opacity: 0.25!important;
}

table.tlayout1 {
	margin: 0 auto;
	width: 100%;
	border: none;
	/* border-top: solid 1px #666;
	border-bottom: solid 1px #666; */
	border-collapse: separate;
	border-spacing: 0 10px;
	/* background: #f5f5f5; */
}
	
table.tlayout1 th {
	vertical-align: middle;
	height: 30px;
	width: 25%;
	/* min-height: 100px; */
	border-right: solid 1px #666;
	padding-right: 10px;
	text-align: right;
	color: #333;
	font-size: 14px;
	font-weight: bold;
}

table.tlayout1 td.data {
	padding-left: 10px;
	/* margin: 50 50 0 0px; */
	margin-left:50px;
	/* height: 53px; */
	width: 100%;
	border: none;
	color: #666;
	font-size: 18px;
	line-height: 16px;
	text-align: left;
	vertical-align: middle;
	white-space: wrap;
	overflow: hidden;
}

table.tlayout1 td:fst-child {
	padding: 0 0 0 10px;
	vertical-align: middle;
}

table.tlayout1 td.bar {
	height: 1px;
	width: 100%;
	border-top: solid 1px #34495e;
}






table.tlayout2 {
	margin: 0 auto;
	width: 100%;
	border: none;
	/* border-top: solid 1px #666;
	border-bottom: solid 1px #666; */
	border-collapse: separate;
	/* border-spacing: 0 10px; */
	
	/* background: #f5f5f5; */
}

table.tlayout2 tbody tr{
	width:100%;
	/* height:30px; */
	font-size: 14px;
	/* margin-top:50px; */
	/* padding-bottom:10px; */
	min-height:35px;
	display:flex;
	align-items: center;
}
table.tlayout2 tr:nth-child(even){
	background-color:#ddd;
}

table.tlayout2 th {
	vertical-align: center;
	height: 25px;
	width: 10em;
	/* min-width:20%; */
	/* min-height: 100px; */
	/* border-right: solid 1px #666; */
	padding-right: 10px;
	text-align: left;
	color: #333;
	font-size: 14px;
	font-weight: bold;
}

table.tlayout2 th div{
	position:relative;
	align-items: center;
	justify-content: center;
	/* text-align: center; */
	text-justify: center;
	/* top:2px; */
}
table.tlayout2 th div div.notification{
	position:absolute;
	bottom:1px;
	right:-15px;
	opacity: 0.7;
	font-size: 12px;
	z-index:10;
	/* height:0; */
}

table.tlayout2 td.data {
	padding-left: 10px;
	/* margin: 50 50 0 0px; */
	/* margin-left:50px; */
	/* height: 53px; */
	width: 100%;
	border: none;
	color: #666;
	border-left: solid 1px #666;
	font-size: 18px;
	line-height: 16px;
	text-align: left;
	vertical-align: middle;
	white-space: wrap;
	overflow: hidden;
	margin-top: 5px;
	margin-bottom:5px;
}

table.tlayout2 td.data div{
	width:100%;
	display:flex;
	align-items: center;
}

table.tlayout2 td:fst-child {
	padding: 0 0 0 10px;
	vertical-align: middle;
}

table.tlayout2 td.bar {
	height: 1px;
	width: 100%;
	border-top: solid 1px #34495e;
}

.sequence-selecter--comment--string{
	font-size: 0.8em;
	color: #666;
}


table.tlayout3 {
    margin: 0 auto;
    width: 100%;
    border: none;
    /* border-top: solid 1px #666;
    border-bottom: solid 1px #666; */
    border-collapse: separate;
    /* border-spacing: 0 10px; */
    
    /* background: #f5f5f5; */
}

table.tlayout3 tbody tr{
    width:100%;
    /* height:30px; */
    font-size: 14px;
    /* margin-top:50px; */
    /* padding-bottom:10px; */
    min-height:35px;
    /* display:flex; */
    align-items: center;
}
table.tlayout3 tr:nth-child(even){
    background-color:#ddd;
}
table.tlayout3 th {
    vertical-align: center;
    height: 25px;
    /* min-width:20%; */
    /* min-height: 100px; */
    /* border-right: solid 1px #666; */
    padding-right: 10px;
    text-align: left;
    background-color: #888;
    color: #111;
    font-weight: bold;
}

.earth-quake-icon-toolbar{
	width:35px;
	height:35px;
	margin-top:3px;
}
.userloginstatus-wrapper{
	display:flex;
	justify-content: flex-end;
}