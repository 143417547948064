/* Made by Jimmy Gillam */
/* Checkmark style starts */
@-moz-keyframes dothabottomcheck {
    0% {
      height: 0;
    }
    100% {
      height: 16px;
    }
  }
  @-webkit-keyframes dothabottomcheck {
    0% {
      height: 0;
    }
    100% {
      height: 16px;
    }
  }
  @keyframes dothabottomcheck {
    0% {
      height: 0;
    }
    100% {
      height: 16px;
    }
  }
  @keyframes dothatopcheck {
    0% {
      height: 0;
    }
    50% {
      height: 0;
    }
    100% {
      height: 38.4px;
    }
  }
  @-webkit-keyframes dothatopcheck {
    0% {
      height: 0;
    }
    50% {
      height: 0;
    }
    100% {
      height: 38.4px;
    }
  }
  @-moz-keyframes dothatopcheck {
    0% {
      height: 0;
    }
    50% {
      height: 0;
    }
    100% {
      height: 38.4px;
    }
  }
  /* input[type=checkbox] { */
  .large-check-box {
    display: none;
  }
  
  .large-check-box {
    height: 32px;
    width: 32px;
    background-color: transparent;
    border: 3.2px solid #000;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-transition: border-color ease 0.2s;
    -o-transition: border-color ease 0.2s;
    -webkit-transition: border-color ease 0.2s;
    transition: border-color ease 0.2s;
    cursor: pointer;
  }
  .large-check-box::before, .large-check-box::after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    height: 0;
    width: 6.4px;
    background-color: #34b93d;
    display: inline-block;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    border-radius: 5px;
    content: " ";
    -webkit-transition: opacity ease 0.5;
    -moz-transition: opacity ease 0.5;
    transition: opacity ease 0.5;
  }
  .large-check-box::before {
    top: 23.04px;
    left: 13.12px;
    box-shadow: 0 0 0 1.6px #ffffff;
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  .large-check-box::after {
    top: 11.84px;
    left: 1.6px;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  input[type=checkbox]:checked + .large-check-box,
  .large-check-box.checked {
    border-color: #34b93d;
  }
  input[type=checkbox]:checked + .large-check-box::after,
  .large-check-box.checked::after {
    height: 16px;
    -moz-animation: dothabottomcheck 0.2s ease 0s forwards;
    -o-animation: dothabottomcheck 0.2s ease 0s forwards;
    -webkit-animation: dothabottomcheck 0.2s ease 0s forwards;
    animation: dothabottomcheck 0.2s ease 0s forwards;
  }
  input[type=checkbox]:checked + .large-check-box::before,
  .large-check-box.checked::before {
    height: 38.4px;
    -moz-animation: dothatopcheck 0.4s ease 0s forwards;
    -o-animation: dothatopcheck 0.4s ease 0s forwards;
    -webkit-animation: dothatopcheck 0.4s ease 0s forwards;
    animation: dothatopcheck 0.4s ease 0s forwards;
  }