.messagetext {
	/* position: relative; */
    /* width: 80%; */
    box-sizing: border-box;
    border-radius: 4px;
    /* margin: 40px 3%; */
    border: solid 1px #538af0;
}
.messagetext div {
    font: 1em sans-serif;
    border-radius: 4px;
	/* box-sizing: border-box; */
	/* width: 100%; */
	/* height:100%; */
	transition: 0.3s;
	/* letter-spacing: 1px; */
	/* color: #aaaaaa; */
    border: none;
    width:100%;
    height:100%;
    /* border-radius: 4px; */
    /* word-break: break-all; */
    /* line-height:1.3; */
}
.messagetext div:focus {
	/* border: 1px solid #538af0; */
	outline: none;
    box-shadow: 0 0 5px 1px rgba(70, 95, 241, 0.5);
    width:100%;
    height:100%;
}

.threadList-unregistered{
    background-color: #ff9898;
}
.threadList-registered{
    background-color: rgb(139, 201, 167);
}
.threadList-closed{
    background-color: #98bafa;
}

.__overlaypage_is_open{
    /* display: block; */
    visibility: visible;
    position:absolute;
    
    /* position: fixed; */
    top:0px;
    left:0px;
    height:100%;
    /* transition-duration: 300ms; */
    /* opacity:0; */
    animation: __overlaypage_fadeInBlur 0.5s ease;
    animation-fill-mode: forwards;
}

@keyframes __overlaypage_fadeInBlur{
    0%{
        opacity: 0.0;
        background-color: rgba(255,255,255,0);
        backdrop-filter: blur(0px);
    }
    100%{
        opacity: 1;
        background-color: rgb(255, 255, 255, 0.6);
        backdrop-filter: blur(15px);
    }
}

@keyframes __overlaypage_fadeOutBlur{
    0%{
        opacity: 1;
        backdrop-filter: blur(15px);
    }
    100%{
        opacity: 0;
        backdrop-filter: blur(0px);
        visibility: hidden;
    }
}

.__overlaypage_is_closed{
    position:absolute;
    /* position: fixed; */
    top:0px;
    left:0px;
    height:100%;
    animation: __overlaypage_fadeOutBlur 0.5s ease 0s;
    animation-fill-mode: forwards;
}

.__overlaypage_is_closed_no_animation{
    position:absolute;
    /* position: fixed; */
    top:0px;
    left:0px;
    height:100%;
    visibility: hidden;
}

/* .backdropfilter-blur10{
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.6)
} */

.__caption_box{
    position: relative;

    margin: 2em 0;
    padding: 0.5em 1em;
    border: solid 3px #3d63df;
    margin-left:2em;
    margin-right:2em;
}

.__caption_box__caption{
    /* position: absolute;
    top: 0;
    left: 0;
  
    font-size: 1em; */
    /* padding: 0 1em; */
    /* margin: 0; */
    /* background-color: white; */
    /* transform: translateY(-50%) translateX(0.2em); */
    position: absolute;
    display: inline-block;
    top: -2.6em;
    left: -3px;
    padding: 0 9px;
    height: 25px;
    background: #3d63df;
    color: #ffffff;
    font-weight: bold;
    border-radius: 5px 5px 0 0;
}

.devicelocationview-closed{
    width:100%;
    animation: __devicelocationview-closed 0.4s ease 0s;
    animation-fill-mode: forwards;
}

@keyframes __devicelocationview-closed{
    0%{
        height:250px;
    }
    100%{
        height:0px;
    }
}

/* @keyframes __leaflet-container-open-blur{
    0%{
        opacity:0;
    }
    70%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
} */

.devicelocationview-open{
    width:100%;
    animation: __devicelocationview-open 0.6s ease 0s;
    animation-fill-mode: forwards;
}

.leaflet-container{
    width:100%;
    height:250px;
    animation: __leaflet-container-open-blur 0.6s ease 0s;
    animation-fill-mode: forwards;
}

@keyframes __devicelocationview-open{
    0%{
        height:0px;
    }
    /* 50%{
        height:0px;
    } */
    100%{
        height:250px;
    }
}

@keyframes __leaflet-container-open-blur{
    0%{
        opacity:0;
        visibility: hidden;
    }
    70%{
        opacity:0;
        visibility: visible;
    }
    100%{
        opacity:1;
        visibility: visible;
    }
}