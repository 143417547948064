/*! * * * * * * * * * * * * * * * * * * * *\  
  CSShake :: shake-horizontal
  v1.5.0
  CSS classes to move your DOM
  (c) 2015 @elrumordelaluz
  http://elrumordelaluz.github.io/csshake/
  Licensed under MIT
\* * * * * * * * * * * * * * * * * * * * */
.shake-horizontal {
    transform-origin: center center; }
  
  .shake-freeze,
  .shake-constant.shake-constant--hover:hover,
  .shake-trigger:hover .shake-constant.shake-constant--hover {
    animation-play-state: paused; }
  
  .shake-freeze:hover,
  .shake-trigger:hover .shake-freeze, .shake-horizontal:hover,
  .shake-trigger:hover .shake-horizontal {
    animation-play-state: running; }
  
  @keyframes shake-horizontal {
    /* 2% {
      transform: translate(-5px, 0) rotate(0); }
    4% {
      transform: translate(-5px, 0) rotate(0); }
    6% {
      transform: translate(3px, 0) rotate(0); }
    8% {
      transform: translate(1px, 0) rotate(0); }
    10% {
      transform: translate(10px, 0) rotate(0); }
    12% {
      transform: translate(6px, 0) rotate(0); }
    14% {
      transform: translate(-2px, 0) rotate(0); }
    16% {
      transform: translate(5px, 0) rotate(0); }
    18% {
      transform: translate(7px, 0) rotate(0); }
    20% {
      transform: translate(3px, 0) rotate(0); }
    22% {
      transform: translate(-1px, 0) rotate(0); }
    24% {
      transform: translate(-7px, 0) rotate(0); }
    26% {
      transform: translate(1px, 0) rotate(0); }
    28% {
      transform: translate(6px, 0) rotate(0); }
    30% {
      transform: translate(10px, 0) rotate(0); }
    32% {
      transform: translate(6px, 0) rotate(0); }
    34% {
      transform: translate(5px, 0) rotate(0); }
    36% {
      transform: translate(-1px, 0) rotate(0); }
    38% {
      transform: translate(1px, 0) rotate(0); }
    40% {
      transform: translate(-9px, 0) rotate(0); }
    42% {
      transform: translate(5px, 0) rotate(0); }
    44% {
      transform: translate(-9px, 0) rotate(0); }
    46% {
      transform: translate(4px, 0) rotate(0); }
    48% {
      transform: translate(0px, 0) rotate(0); }
    50% {
      transform: translate(-7px, 0) rotate(0); }
    52% {
      transform: translate(8px, 0) rotate(0); }
    54% {
      transform: translate(7px, 0) rotate(0); }
    56% {
      transform: translate(9px, 0) rotate(0); }
    58% {
      transform: translate(10px, 0) rotate(0); }
    60% {
      transform: translate(-6px, 0) rotate(0); }
    62% {
      transform: translate(8px, 0) rotate(0); }
    64% {
      transform: translate(8px, 0) rotate(0); }
    66% {
      transform: translate(-9px, 0) rotate(0); }
    68% {
      transform: translate(-2px, 0) rotate(0); }
    70% {
      transform: translate(-8px, 0) rotate(0); }
    72% {
      transform: translate(4px, 0) rotate(0); }
    74% {
      transform: translate(4px, 0) rotate(0); }
    76% {
      transform: translate(-9px, 0) rotate(0); }
    78% {
      transform: translate(-9px, 0) rotate(0); }
    80% {
      transform: translate(7px, 0) rotate(0); }
    82% {
      transform: translate(-5px, 0) rotate(0); }
    84% {
      transform: translate(-5px, 0) rotate(0); }
    86% {
      transform: translate(4px, 0) rotate(0); }
    88% {
      transform: translate(9px, 0) rotate(0); }
    90% {
      transform: translate(-6px, 0) rotate(0); }
    92% {
      transform: translate(2px, 0) rotate(0); }
    94% {
      transform: translate(8px, 0) rotate(0); }
    96% {
      transform: translate(-1px, 0) rotate(0); }
    98% {
      transform: translate(-3px, 0) rotate(0); }
    0%, 100% {
      transform: translate(0, 0) rotate(0); } } */
      0% {transform: translate(-30.078118000000003px, 0) rotate(0); }
      1% {transform: translate(-6.733801545773915px, 0) rotate(0); }
      2% {transform: translate(19.024034689331813px, 0) rotate(0); }
      3% {transform: translate(35.041518362537374px, 0) rotate(0); }
      4% {transform: translate(34.215474258832955px, 0) rotate(0); }
      5% {transform: translate(17.613849244472576px, 0) rotate(0); }
      6% {transform: translate(-6.354667887032335px, 0) rotate(0); }
      7% {transform: translate(-26.173355844722423px, 0) rotate(0); }
      8% {transform: translate(-32.73371033263554px, 0) rotate(0); }
      9% {transform: translate(-23.501372280131235px, 0) rotate(0); }
      10% {transform: translate(-3.436444300535058px, 0) rotate(0); }
      11% {transform: translate(17.631286713324464px, 0) rotate(0); }
      12% {transform: translate(29.8002677462175px, 0) rotate(0); }
      13% {transform: translate(27.734616719306516px, 0) rotate(0); }
      14% {transform: translate(12.976964780645638px, 0) rotate(0); }
      15% {transform: translate(-7.051820190408563px, 0) rotate(0); }
      16% {transform: translate(-22.763825037956707px, 0) rotate(0); }
      17% {transform: translate(-26.97947810231268px, 0) rotate(0); }
      18% {transform: translate(-18.190613619310206px, 0) rotate(0); }
      19% {transform: translate(-1.0369818660377774px, 0) rotate(0); }
      20% {transform: translate(16.114116979196055px, 0) rotate(0); }
      21% {transform: translate(25.2350190578083px, 0) rotate(0); }
      22% {transform: translate(22.38139791176709px, 0) rotate(0); }
      23% {transform: translate(9.373283400316339px, 0) rotate(0); }
      24% {transform: translate(-7.289987630522205px, 0) rotate(0); }
      25% {transform: translate(-19.661133979470556px, 0) rotate(0); }
      26% {transform: translate(-22.12331340000036px, 0) rotate(0); }
      27% {transform: translate(-13.924884873041908px, 0) rotate(0); }
      28% {transform: translate(0.6655708836646355px, 0) rotate(0); }
      29% {transform: translate(14.560273079485086px, 0) rotate(0); }
      30% {transform: translate(21.28379147053993px, 0) rotate(0); }
      31% {transform: translate(17.979175816717166px, 0) rotate(0); }
      32% {transform: translate(6.598819147433147px, 0) rotate(0); }
      33% {transform: translate(-7.203916119093748px, 0) rotate(0); }
      34% {transform: translate(-16.8705198903833px, 0) rotate(0); }
      35% {transform: translate(-18.04373194096236px, 0) rotate(0); }
      36% {transform: translate(-10.51796813390478px, 0) rotate(0); }
      37% {transform: translate(1.8323856508202283px, 0) rotate(0); }
      38% {transform: translate(13.031455367753253px, 0) rotate(0); }
      39% {transform: translate(17.88395797754316px, 0) rotate(0); }
      40% {transform: translate(14.375338292348935px, 0) rotate(0); }
      41% {transform: translate(4.486046417381758px, 0) rotate(0); }
      42% {transform: translate(-6.897029226583491px, 0) rotate(0); }
      43% {transform: translate(-14.385488609259419px, 0) rotate(0); }
      44% {transform: translate(-14.631807584261662px, 0) rotate(0); }
      45% {transform: translate(-7.813632690623997px, 0) rotate(0); }
      46% {transform: translate(2.5919587279703222px, 0) rotate(0); }
      47% {transform: translate(11.569403406497997px, 0) rotate(0); }
      48% {transform: translate(14.974473977742292px, 0) rotate(0); }
      49% {transform: translate(11.438786731026113px, 0) rotate(0); }
      50% {transform: translate(2.898008415867892px, 0) rotate(0); }
      51% {transform: translate(-6.447800794166888px, 0) rotate(0); }
      52% {transform: translate(-12.191666042698905px, 0) rotate(0); }
      53% {transform: translate(-11.790801885851273px, 0) rotate(0); }
      54% {transform: translate(-5.681386847999214px, 0) rotate(0); }
      55% {transform: translate(3.046091651045785px, 0) rotate(0); }
      56% {transform: translate(10.200739703586727px, 0) rotate(0); }
      57% {transform: translate(12.497362917251948px, 0) rotate(0); }
      58% {transform: translate(9.057504066663945px, 0) rotate(0); }
      59% {transform: translate(1.7232643192448762px, 0) rotate(0); }
      60% {transform: translate(-5.914957570331794px, 0) rotate(0); }
      61% {transform: translate(-10.26970938663198px, 0) rotate(0); }
      62% {transform: translate(-9.435513960386434px, 0) rotate(0); }
      63% {transform: translate(-4.012714787997986px, 0) rotate(0); }
      64% {transform: translate(3.274880040702051px, 0) rotate(0); }
      65% {transform: translate(8.940799593551745px, 0) rotate(0); }
      66% {transform: translate(10.39864147028917px, 0) rotate(0); }
      67% {transform: translate(7.136259706704777px, 0) rotate(0); }
      68% {transform: translate(0.87157844672506px, 0) rotate(0); }
      69% {transform: translate(-5.34170653068918px, 0) rotate(0); }
      70% {transform: translate(-8.597475671008052px, 0) rotate(0); }
      71% {transform: translate(-7.491458514705499px, 0) rotate(0); }
      72% {transform: translate(-2.7177697866241317px, 0) rotate(0); }
      73% {transform: translate(3.3408569158255403px, 0) rotate(0); }
      74% {transform: translate(7.796637919597536px, 0) rotate(0); }
      75% {transform: translate(8.62882825392871px, 0) rotate(0); }
      76% {transform: translate(5.594483210737408px, 0) rotate(0); }
      77% {transform: translate(0.27026073685412655px, 0) rotate(0); }
      78% {transform: translate(-4.759152705327239px, 0) rotate(0); }
      79% {transform: translate(-7.151607914547611px, 0) rotate(0); }
      80% {transform: translate(-5.8939520019881115px, 0) rotate(0); }
      81% {transform: translate(-1.7224892264650151px, 0) rotate(0); }
      82% {transform: translate(3.2924187403633454px, 0) rotate(0); }
      83% {transform: translate(6.769370654306826px, 0) rotate(0); }
      84% {transform: translate(7.143148864749918px, 0) rotate(0); }
      85% {transform: translate(4.364323196515102px, 0) rotate(0); }
      86% {transform: translate(-0.1389251167395642px, 0) rotate(0); }
      87% {transform: translate(-4.189048083446075px, 0) rotate(0); }
      88% {transform: translate(-5.908668286222209px, 0) rotate(0); }
      89% {transform: translate(-4.5871650065492915px, 0) rotate(0); }
      90% {transform: translate(-0.9660939126364951px, 0) rotate(0); }
      91% {transform: translate(3.1666458822738974px, 0) rotate(0); }
      92% {transform: translate(5.855982051045181px, 0) rotate(0); }
      93% {transform: translate(5.901524852486318px, 0) rotate(0); }
      94% {transform: translate(3.3888968407300624px, 0) rotate(0); }
      95% {transform: translate(-0.4023619361114754px, 0) rotate(0); }
      96% {transform: translate(-3.645990196849425px, 0) rotate(0); }
      97% {transform: translate(-4.845922177472374px, 0) rotate(0); }
      98% {transform: translate(-3.5231820267676355px, 0) rotate(0); }
      99% {transform: translate(-0.3989333918405009px, 0) rotate(0); }}
  
  .shake-horizontal:hover,
  .shake-trigger:hover .shake-horizontal, .shake-horizontal.shake-freeze, .shake-horizontal.shake-constant {
    display: inline-block;
    animation-name: shake-horizontal;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite; }

.shake-horizontal-slow{
  display: inline-block;
  transform-origin: center center;
  animation: shake-horizontal;
  animation-duration: 6000ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}